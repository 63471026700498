import topbar from "topbar";

topbar.config({
  autoRun      : true, 
  barThickness : 2,
  barColors    : {
    '0'        : 'rgba(26,  188, 156, .7)',
    '.3'       : 'rgba(41,  128, 185, .7)',
    '1.0'      : 'rgba(231, 76,  60,  .7)'
  },
  shadowBlur   : 5,
  shadowColor  : 'rgba(0, 0, 0, .5)',
  className    : 'topbar',
});

topbar.show();

document.addEventListener("DOMContentLoaded", () => { topbar.hide() });